@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  padding: 0;
  color: #1f3933;
  background-color: #f2f0eb;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
}

/* navbar  */

.logo-text {
  font-family: "Dancing Script", cursive;
  font-size: 30px;
  font-weight: 800;
  color: #1f3933;
  margin-right: 15px;
}

.bar {
  background: #f2f0eb;
  min-height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
}

.bar a {
  text-decoration: none;
}

.logo-container {
  height: 100%;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-container {
  padding: 50px;
}

.cart-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  position: absolute;
  right: 50px;
  color: #1f3933;
  fill: #1f3933;
  text-decoration: none;
  font-weight: bold;
}

.cart-container svg {
  stroke: #1f3933;
  stroke-width: 1px;
  width: 30px;
  height: 30px;
}

.cart-badge {
  width: 20px;
  height: 20px;
  background-color: rgb(223, 59, 59);
  border-radius: 50%;
  position: absolute;
  right: -7px;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-badge p {
  margin: 0;
  color: #eee;
  font-size: 12px;
  margin-bottom: 2px;
}

.cart-name {
  text-align: center;
}

.links-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 139px;
  position: absolute;
  right: 140px;
  font-weight: bold;
}

.signin-link {
  color: #1f3933;
  fill: #1f3933;
  text-decoration: none;
}

.signup-link {
  color: #1f3933;
  fill: #1f3933;
  text-decoration: none;
}

.profile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  position: absolute;
  right: 140px;
  font-weight: bold;
}

.profile-image-wrapper {
  background: #000;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  overflow: hidden;
}

.profile-image {
  width: 65px;
  margin-top: -17px;
  margin-left: -12px;
}

/* menu page */

.categories-container {
  display: flex;
  padding: 50px;
  justify-content: center;
}

hr {
  border-top: #fff;
}

.product-image-wrapper {
  background: #1f3933;
  height: 150px;
  width: 150px;
  border-radius: 100%;
  overflow: hidden;
}

.product-container {
  text-align: center;
  width: 150px;
  font-weight: 400;
  font-size: 22px;
  position: relative;
  margin: 20px;
}

.product-container img {
  width: 240px;
  margin-top: -20px;
  margin-left: -45px;
}

.product-container a {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.drinks-container {
  padding: 50px;
}

.drinks-map {
  padding: 50px;
  display: flex;
  justify-content: center;
}

.product-head {
  background-color: #1f3933;
  min-height: 250px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.product-head img {
  height: 250px;
  width: 250px;
}

.product-head {
  color: #fff;
}

.product-image-container {
  border-radius: 8px;
}

.product-image {
  height: 500px;
  width: 500px;

  border-radius: 8px;
}

.product-info-container {
  padding-left: 60px;
}

.product-body {
  display: flex;
  justify-content: space-around;
  padding: 60px;
}

.product-name {
  font-size: 25px;
  font-weight: bold;
}

.product-price {
  font-size: 22px;
}

.product-description {
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.product-size {
  font-size: 20px;
  margin-top: 15px;
  font-weight: bold;
}

.options-container {
  display: flex;
  margin-top: 10px;
}

.options-container form {
  display: flex;
}

.size-option {
  display: flex;
}

.size-option input {
  text-align: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  display: flex;
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  padding: 0;
  cursor: pointer;
}

.size-option input[type="radio"] {
  display: none;
}

.size-option label {
  width: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  align-items: center;
  margin: 5px;
  border-radius: 8px;
}

.size-option label:hover {
  border: 1px solid #ccc;
}

.size-option input[type="radio"]:checked + label {
  background-color: #eee;
  border: 2px solid #1f3933;
}

.product-quantity {
  font-size: 20px;
  margin-top: 15px;
  font-weight: bold;
}

.app-button {
  margin-top: 20px;
  height: 50px;
  width: 100%;
  color: #f2f0eb;
  border: #1f3933 solid;
  background-color: #1f3933;
  font-weight: bold;
  text-align: center;
}

.app-button:hover {
  color: #1f3933;
  background-color: #fff;
}

.app-button:active {
  background-color: #eee;
}

.app-button:disabled {
  color: #ccc;
  background-color: #eee;
  border: #ccc;
}

.counter-button {
  width: 40px;
  height: 40px;
  margin-top: 0;
  font-size: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.button-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.button-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sub-counter-container {
  display: flex;
  margin-top: 10px;
}

.counter {
  width: 50px;
  border: 1px solid #1f3933;
  background-color: #fff;
}

.results {
  width: 40px;
  height: 20px;
  text-align: center;
  padding: 10px 0;
  background: #eee;
}

.warning-message {
  color: rgb(223, 59, 59);
  font-size: 15px;
}

.warning-message-container {
  text-align: center;
}

.cart-title h2 {
  margin: 10px;
  text-align: center;
  color: #1f3933;
}

.cart-product-container {
  padding: 0 10%;
}

.cart-product-headings {
  display: block;
  padding: 0;
  margin: 0 0 0 30px;
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
}

.cart-table tr {
  border-bottom: 0.5pt solid #ddd;
}

.cart-table th,
td {
  text-align: center;
  padding: 23px;
}

.cart-table img {
  height: 70px;
  width: 70px;
}

.cart-total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
}

.sub-total,
.total,
.tax,
.discount {
  display: flex;
  justify-content: space-between;
  width: 20%;
  margin: 10px 0;
  border-bottom: 1px dashed #ddd;
}

.discount {
  color: rgb(136, 200, 40);
}

.checkout-button-container {
  display: flex;
  justify-content: flex-end;
}

.checkout-button {
  font-size: 20px;
  border-radius: 8px;
}

.delete-cross {
  color: #ccc;
}

.delete-cross svg {
  cursor: pointer;
}

.coupon-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.coupon {
  width: 20%;
}

.coupon label {
  font-weight: 700;
  margin-right: 5px;
  font-size: 10px;
}

.input-container {
  width: 35%;
  margin: 5px;
}

.coupon-input {
  width: 100%;
  border: 1px solid #898989;
  border-radius: 5px;
  height: 30px;
  box-sizing: border-box;
}

.coupon-input:focus {
  background: #eee;
  outline-color: #62b6a2;
}

.textfield-error {
  font-size: 14px;
  color: rgb(223, 59, 59);
}

.coupon-wrapper {
  display: flex;
  align-items: center;
}

.apply-button {
  width: 53px;
  height: 34px;
  border-radius: 5px;
  margin: 0px 5px;
}

.delete-icon-container {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.delete-icon-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.size-badge {
  background-color: #ccc;
  width: 30%;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px 0;
  font-size: 12px;
}

.click-add-to-cart {
  font-size: 20px;
  border-radius: 8px;
}

.shipping-information-container {
  align-items: center;
}
.shipping-title h2 {
  margin: 10px;
  text-align: center;
  color: #1f3933;
}

.shipping-information {
  padding: 0 10%;
}

/* .shipping-information input{
  width: 100%;
  border: 1px solid #898989;
  border-radius: 5px;
  height: 30px;
} */

.inputs-name-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.select-container {
  width: 35%;
  margin: 5px;
}

.first-option {
  display: block;
  width: 100%;
  border: 1px solid #898989;
  border-radius: 5px;
  height: 30px;
}

.first-option:focus {
  background: #eee;
  outline-color: #62b6a2;
}

.first-option:not(:focus) {
  color: #898989;
}

.pickups-buttons h4 {
  text-align: center;
  margin-bottom: 5px;
}

.pickups-options {
  display: flex;
  justify-content: center;
}

.checkout-button-container {
  align-items: center;
  padding: 20px;
}

.checkout-button {
  margin: 0 auto;
  width: 70%;
}

.thank-you-container {
  background-color: #f2f0eb;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.thankyou-content-container {
  background-color: #eee;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0px 0px 23px 0px #0000004a;
  margin-top: 120px;
}

.thankyou-heading {
  width: 100%;
  text-align: center;
  padding-top: 30px;
  color: #1f3933;
}

.thankyou-heading span {
  font-weight: bold;
}

.thankyou-heading p {
  margin: 10px;
}

.thankyou-heading a {
  text-decoration: none;
}

.home-button {
  border-radius: 30px !important;
  width: 200px;
}

.error-label-container {
  display: flex;
  justify-content: center;
}

.auth-container {
  background-color: #f2f0eb;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.auth-content {
  background-color: #eee;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0px 0px 23px 0px #0000004a;
  margin-top: 120px;
}

.auth-heading h1 {
  color: #1f3933;
}

.auth-form {
  width: 100%;
  padding: 15px;
  margin: auto;
  height: max-content;
}

.auth-input {
  width: 300px;
  height: 30px;
  margin: 5px;
}

.home-cta-container {
  min-height: 100px;
  width: 100%;
  position: relative;
}

.cta-image {
  width: 100%;
}

.home-p-1 {
  color: #127a62;
  font-size: 25px;
  position: absolute;
  top: 10%;
  right: 38%;
}

.home-p-2 {
  color: #1f3933;
  font-size: 50px;
  font-family: sofia-pro, sans-serif;
  position: absolute;
  top: 15%;
  right: 33%;
}

.home-p-3 {
  color: #0c6651;
  font-size: 23px;
  position: absolute;
  top: 23%;
  right: 34%;
  font-family: "Ibarra Real Nova", serif;
}

.menu-button {
  border-radius: 30px !important;
  width: 200px;
  position: absolute;
  top: 27%;
  right: 41%;
}

.home-content-container {
  display: block;
  width: 100%;
  background-color: #f2f0eb;
  padding: 7% 0;
}

.home-p-4 {
  color: #127a62;
  font-size: 25px;
  text-align: center;
}

.home-p-5 {
  color: #1f3933;
  font-size: 20px;
  text-align: center;
}

.home-banner {
  width: 100%;
  position: relative;
}

.home-banner-image {
  width: 100%;
}

.home-p-6 {
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 16%;
  right: 62%;
  color: #f2f0eb;
}

.home-p-7 {
  font-size: 50px;
  font-weight: 600;
  position: absolute;
  top: 22%;
  right: 65%;
  color: #f2f0eb;
}

.home-p-8 {
  font-size: 50px;
  font-weight: 600;
  position: absolute;
  top: 30%;
  right: 59%;
  color: #f2f0eb;
}

.home-p-9 {
  font-size: 20px;
  position: absolute;
  top: 43%;
  right: 53%;
  color: #f2f0eb;
  text-align: justify;
  max-width: 310px;
}

.home-about-us-container {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.about-us-image {
  width: 300px;
  height: 375px;
  border-radius: 8px;
}

.home-p-10 {
  font-size: 16px;
  font-weight: 600;
  color: #127a62;
}

.home-p-11 {
  font-size: 50px;
  font-weight: 600;
  color: #1f3933;
  font-family: "Ibarra Real Nova", serif;
}

.home-p-12 {
  font-size: 20px;
  color: #0c6651;
  max-width: 310px;
}

.light {
  color: #f2f0eb;
  text-decoration: none;
}

.roast-container {
  background-color: #1f3933;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.swiper-container {
  height: 500px;
  width: 100%;
  background-color: #f2f0eb;
}

.footer-container {
  height: 400px;
  width: 100%;
  background-color: #1f3933;
}

.footer-logo-container {
  width: 100%;
  align-items: center;
  text-align: center;
  padding-top: 10px;
}

.home-logo-text {
  font-family: "Dancing Script", cursive;
  font-size: 40px;
  font-weight: 800;
  color: #f2f0eb;
  text-decoration: none;
}

.home-p-13 {
  font-size: 12px;
  margin-top: 10px;
}

.home-links-container {
  display: flex;
  justify-content: space-around;
  height: 100px;
  align-items: center;
  width: 50%;
  margin: auto;
}

.home-p-14 {
  font-size: 20px;
  font-weight: 600;
}

.home-location-container {
  display: flex;
}
.globe-svg {
  margin-right: 20px;
}
.footer-legal-links {
  display: flex;
  justify-content: space-around;
  height: 65px;
  width: 40%;
  margin: auto;
}

.home-p-15 {
  color: #566a65;
  font-size: 15px;
  text-decoration: none;
}

.social-accounts-container {
  display: flex;
  justify-content: space-around;
  height: 60px;
  width: 40%;
  margin: auto;
}

.social-account {
  stroke: #566a65;
  fill: #566a65;
  stroke-width: 1px;
}

.home-p-16 {
  width: 40%;
  color: #566a65;
  font-size: 15px;
  text-align: center;
  margin: auto;
}

.popular-drinks-container {
  background-color: #fff;
  text-align: center;
}

.cards-container {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  margin: auto;
}

.card-wrapper {
  background-color: #f2f0eb;
  width: 20%;
  height: 80%;
  margin: 15px;
}

.popular-drink-link {
  text-decoration: none;
  color: #1f3933;
}

.popular-drink-name {
  text-align: center;
  font-size: 20px;
}

.popular-drink-description {
  text-align: center;
  font-size: 15px;
}

.section-title {
  font-size: 32px;
  color: #0c6651;
  font-weight: 600;
  padding-top: 20px;
}

@media (max-width: 708px) {
  /* home page  */
  .home-p-1 {
    font-size: 20px;
    top: 10%;
    right: 33%;
    margin-bottom: 10px;
  }
  .home-p-2 {
    font-size: 40px;
    top: 15%;
    right: 20%;
    margin: 10px;
  }
  .home-p-3 {
    font-size: 23px;
    top: 23%;
    right: 17%;
    margin: 15px 0;
  }
  .menu-button {
    width: 200px;
    top: 32%;
    right: 36%;
  }

  .home-p-6 {
    font-size: 18px;
    top: 10%;
    right: 62%;
  }

  .home-p-7 {
    font-size: 45px;
    font-weight: 600;
    top: 17%;
    right: 68%;
  }

  .home-p-8 {
    font-size: 40px;
    font-weight: 600;
    top: 30%;
    right: 59%;
  }

  .home-p-9 {
    font-size: 16px;
    top: 44%;
    right: 40%;
  }
  .roast-content-container {
    margin-left: 25px;
  }
  .cards-container {
    height: 300px;
  }
  .home-links-container {
    height: 80px;
  }
  .home-p-14 {
    font-size: 17px;
  }
  .footer-legal-links {
    height: 40px;
  }
  .social-accounts-container {
    height: 40px;
  }
  .social-account {
    height: 20px;
  }
  .home-p-16 {
    width: 70%;
  }

  /* menu page */
  .drinks-map {
    flex-wrap: wrap;
  }

  /* single product page  */

  .product-body {
    display: block;
  }

  .product-info-container {
    margin-top: 30px;
  }

  /* cart page  */
  .size-badge {
    font-size: 10px;
  }

  .coupon {
    width: 45%;
  }
  .sub-total {
    width: 45%;
  }
  .tax {
    width: 45%;
  }
  .discount {
    width: 45%;
  }
  .total {
    width: 45%;
  }

  /* thankyou page  */
  .thank-you-container {
    width: 70%;
    margin: auto;
  }
  .thankyou-content-container {
    padding: 30px 90px;
    margin-bottom: 30px;
  }

  /* authintcation pages */
  .auth-container {
    margin-bottom: 30px;
  }
}

@media (max-width: 515px) {
  /* navbar  */
  .logo-text {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  .logo-container {
    width: 115px;
  }

  .menu-container {
    padding: 50px;
  }

  .menu-text {
    font-size: 15px;
  }

  .links-container {
    width: 129px;
  }

  .signin-link {
    font-size: 15px;
    font-weight: 500;
  }

  .signup-link {
    font-size: 15px;
    font-weight: 500;
  }

  .profile-container {
    width: 80px;
    right: 140px;
    font-weight: 500;
  }

  .profile-image-wrapper {
    height: 30px;
    width: 30px;
  }

  .cart-container {
    font-weight: 500;
  }

  .cart-container svg {
    width: 25px;
    height: 25px;
  }

  .cart-badge {
    right: -10px;
    top: -7px;
  }

  .cart-badge p {
    font-size: 10px;
  }

  /* home page  */
  .home-p-1 {
    font-size: 15px;
    top: 5%;
  }
  .home-p-2 {
    font-size: 30px;
    top: 13%;
  }
  .home-p-3 {
    font-size: 18px;
    margin: 15px 0;
  }
  .menu-button {
    width: 150px;
    top: 37%;
  }

  .home-p-4 {
    font-size: 20px;
  }

  .home-p-5 {
    font-size: 15px;
  }
  .home-p-6 {
    font-size: 12px;
    font-weight: 400;
    top: 7%;
    right: 65%;
  }

  .home-p-7 {
    font-size: 30px;
    top: 17%;
    right: 70%;
  }

  .home-p-8 {
    font-size: 30px;
    top: 30%;
    right: 59%;
  }

  .home-p-9 {
    font-size: 12px;
    top: 46%;
    right: 21%;
  }

  .home-about-us-container {
    height: 300px;
  }

  .about-us-image {
    width: 250px;
    height: 250px;
    margin-top: 10px;
  }

  .about-us-content {
    margin-left: 15px;
  }

  .home-p-10 {
    font-size: 10px;
  }

  .home-p-11 {
    font-size: 30px;
  }

  .home-p-12 {
    font-size: 15px;
  }

  .roast-content-container {
    margin-left: 25px;
  }

  .cards-container {
    height: 200px;
  }

  .section-title {
    font-size: 25px;
  }

  .popular-drink-name {
    font-size: 15px;
  }

  .popular-drink-description {
    font-size: 10px;
  }

  .home-logo-text {
    font-size: 30px;
  }

  .home-links-container {
    height: 80px;
  }

  .home-p-13 {
    font-size: 10px;
  }

  .home-p-14 {
    font-size: 15px;
    font-weight: 400;
  }

  .globe-svg {
    width: 20px;
    height: 20px;
  }

  .footer-legal-links {
    height: 40px;
  }

  .home-p-15 {
    font-size: 12px;
  }

  .social-accounts-container {
    height: 40px;
  }
  .social-account {
    height: 20px;
  }
  .home-p-16 {
    font-size: 12px;
    width: 70%;
  }

  /* categoreis page */

  .categories-container {
    flex-wrap: wrap;
  }

  /* menu page */
  .drinks-map {
    flex-wrap: wrap;
  }

  /* single product page  */

  .product-image {
    height: 300px;
    width: 300px;
  }

  .product-image-container {
    width: 320px;
    height: 320px;
  }

  .product-body {
    display: block;
    padding: 70px;
  }

  /* cart page  */

  .cart-product-container {
    padding: 0;
  }
  .size-badge {
    font-size: 10px;
  }

  .coupon {
    width: 45%;
  }
  .sub-total {
    width: 45%;
  }
  .tax {
    width: 45%;
  }
  .discount {
    width: 45%;
  }
  .total {
    width: 45%;
  }

  .input-container {
    width: 80%;
  }

  .select-container {
    width: 80%;
  }

  /* thankyou page  */
  .thank-you-container {
    width: 70%;
    margin: auto;
  }
  .thankyou-content-container {
    padding: 30px 90px;
    margin-bottom: 30px;
  }

  /* authintcation pages */
  .auth-container {
    margin-bottom: 30px;
  }

  .auth-content {
    margin: 40px;
  }

  .auth-heading h1 {
    font-size: 20px;
  }

  .auth-input {
    width: 250px;
  }
}

@media (max-width: 360px) {
  /* navbar  */
  .logo-text {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
  }

  .logo-container {
    width: 90px;
  }

  .menu-container {
    padding: 50px;
  }

  .menu-text {
    font-size: 15px;
  }

  .links-container {
    width: 120px;
    right: 100px;
  }

  .signin-link {
    font-size: 15px;
    font-weight: 400;
  }

  .signup-link {
    font-size: 15px;
    font-weight: 400;
  }

  .profile-container {
    width: 80px;
    right: 140px;
    font-weight: 500;
  }

  .profile-image-wrapper {
    height: 30px;
    width: 30px;
  }

  .cart-container {
    font-weight: 400;
    right: 20px;
    width: 70px;
  }

  .cart-container svg {
    width: 25px;
    height: 25px;
  }

  .cart-badge {
    right: -10px;
    top: -7px;
  }

  .cart-badge p {
    font-size: 10px;
  }

  /* home page  */
  .home-p-1 {
    font-size: 15px;
    top: 5%;
    right: 22%;
  }
  .home-p-2 {
    font-size: 25px;
    top: 13%;
    right: 13%;
  }
  .home-p-3 {
    font-size: 15px;
    margin: 15px 0;
    right: 10%;
  }
  .menu-button {
    width: 150px;
    top: 37%;
    right: 27%;
  }

  .home-p-4 {
    font-size: 17px;
  }

  .home-p-5 {
    font-size: 12px;
  }
  .home-p-6 {
    font-size: 12px;
    font-weight: 400;
    top: 7%;
    right: 64%;
  }

  .home-p-7 {
    font-size: 27px;
    top: 17%;
    right: 72%;
  }

  .home-p-8 {
    font-size: 27px;
    top: 30%;
    right: 59%;
  }

  .home-p-9 {
    font-size: 10px;
    top: 46%;
    right: 5%;
  }

  .home-about-us-container {
    height: 300px;
  }

  .about-us-image {
    width: 250px;
    height: 173px;
    margin-left: 20px;
  }

  .about-us-content {
    margin-left: 15px;
  }

  .home-p-10 {
    font-size: 10px;
  }

  .home-p-11 {
    font-size: 30px;
  }

  .home-p-12 {
    font-size: 15px;
  }

  .roast-content-container {
    margin-left: 25px;
  }

  .popular-drinks-container {
    height: 500px;
  }

  .cards-container {
    height: 200px;
    width: 100%;
    flex-wrap: wrap;
  }

  .card-wrapper {
    margin: 10px 30px;
  }

  .section-title {
    font-size: 25px;
  }

  .popular-drink-name {
    font-size: 15px;
  }

  .popular-drink-description {
    font-size: 10px;
  }

  .home-logo-text {
    font-size: 30px;
  }

  .home-links-container {
    height: 80px;
    width: 70%;
  }

  .home-p-13 {
    font-size: 10px;
  }

  .home-p-14 {
    font-size: 15px;
    font-weight: 400;
  }

  .globe-svg {
    width: 20px;
    height: 20px;
  }

  .footer-legal-links {
    height: 40px;
    width: 70%;
  }

  .home-p-15 {
    font-size: 12px;
  }

  .social-accounts-container {
    height: 40px;
  }
  .social-account {
    height: 20px;
  }
  .home-p-16 {
    font-size: 12px;
    width: 70%;
  }

  /* categoreis page */

  .categories-container {
    flex-wrap: wrap;
  }

  /* menu page */
  .drinks-map {
    flex-wrap: wrap;
  }

  /* single product page  */

  .product-head {
    min-height: 200px;
  }

  .product-head img {
    height: 150px;
    width: 150px;
  }

  .product-head h2 {
    font-size: 0.5em;
  }

  .product-image {
    height: 200px;
    width: 200px;
  }

  .product-image-container {
    width: 320px;
    height: 210px;
  }

  .product-body {
    display: block;
    padding: 70px;
  }

  .product-name {
    font-size: 20px;
  }

  .product-price {
    font-size: 15px;
  }

  .product-description {
    font-size: 15px;
  }

  .product-size {
    font-size: 15px;
  }

  .size-option label {
    width: 70px;
    margin: 2px;
  }
  .product-quantity {
    font-size: 15px;
  }

  .click-add-to-cart {
    font-size: 15px;
    height: 30px;
    width: 70%;
  }

  /* cart page  */

  .cart-product-container {
    padding: 0;
  }

  .cart-table td {
    padding: 10px;
  }

  .size-badge {
    font-size: 10px;
  }

  .coupon-container {
    padding: 0 30px;
    justify-content: center;
  }

  .coupon-wrapper {
    width: 110%;
  }

  .coupon {
    width: 53%;
  }

  .apply-button {
    height: 25px;
  }

  .cart-total {
    align-items: center;
  }

  .input-container {
    width: 80%;
  }

  .select-container {
    width: 80%;
  }

  /* thankyou page  */
  .thank-you-container {
    width: 70%;
    margin: auto;
  }
  .thankyou-content-container {
    padding: 30px 90px;
    margin-bottom: 30px;
  }

  /* authintcation pages */
  .auth-container {
    margin-bottom: 30px;
  }

  .auth-content {
    margin: 40px;
  }

  .auth-heading h1 {
    font-size: 20px;
  }

  .auth-input {
    width: 250px;
  }
}
